import { createSlice } from '@reduxjs/toolkit'
import { InputModel } from '@/features/input/types'

interface IState {
  list: InputModel[]
  loading: boolean
  error: any | null
  meta: any | null
}

const initialState: IState = {
  list: [],
  loading: false,
  error: null,
  meta: null,
}

const inputInputsListSlice = createSlice({
  name: 'inputInputsList',
  initialState,
  reducers: {
    getList(state, action) {
      state.loading = true
      state.error = null
    },
    getListSuccess(state, action) {
      state.loading = false
      state.list = action.payload.data
      state.meta = action.payload.meta
    },
    getListError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    updateListItem(state, action) {
      state.list = state.list.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          }
        } else {
          return item
        }
      })
    },
    cleanState() {
      return initialState
    },
  },
})

export const inputInputsList = inputInputsListSlice.actions

export default inputInputsListSlice.reducer
