import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { BsBarChart, BsPeople } from 'react-icons/bs'
import { NavLink, NavLinkType } from '../NavLink'

interface IProps {
  onLinkClick: () => void
}

export const GeneralLinks = ({ onLinkClick }: IProps) => {
  const { t } = useTranslation()

  const links: Array<NavLinkType> = [
    {
      path: '/dashboard',
      label: t('dashboard'),
      icon: BsBarChart,
    },
    {
      path: '/users',
      label: t('users'),
      icon: BsPeople,
    },
  ]

  return (
    <>
      {links.map((item) => {
        return <NavLink key={item.path} onClick={onLinkClick} {...item} />
      })}
    </>
  )
}
