import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { EN } from '@/locales/en/translations'

const resources = {
  en: {
    translation: EN,
  },
}

const DEFAULT_LANGUAGE_CODE = process.env.REACT_APP_DEFAULT_LANGUAGE_CODE

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['localStorage'],
    },
    resources,
    fallbackLng: DEFAULT_LANGUAGE_CODE,
    supportedLngs: ['en'], // TODO: global const
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    debug: false,

    // react-i18next options
    react: {
      useSuspense: true,
    },

    returnNull: false,
  })

export default i18n
