import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  const icon = getRef('icon')
  const dark = theme.colorScheme === 'dark'

  const color = theme.primaryColor
  const variant = 'light'

  const colors = theme.fn.variant({ variant, color })

  const secondaryColor = theme.colors['secondary'][theme.fn.primaryShade()]

  return {
    link: {
      ...theme.fn.focusStyles(),
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: dark ? theme.colors.dark[0] : theme.colors.gray[7],
      padding: `${theme.spacing.xs}px ${theme.spacing.lg}px`,
      minHeight: '46px',
      position: 'relative',

      '&:hover': {
        color: colors.color,

        // colored svg path with fill
        [`&:not(.link_icon_stroke) .${icon} > path`]: {
          fill: secondaryColor,
        },
        // colored svg path with stroke
        [`&.link_icon_stroke .${icon} path`]: {
          stroke: secondaryColor,
        },
      },
    },

    linkIcon: {
      ref: icon,
      color: dark ? theme.colors.dark[0] : theme.colors.gray[7],
    },

    rightSection: {},

    linkActive: {
      '&, &:hover': {
        backgroundColor: colors.background,
        color: colors.color,
        fontWeight: 600,

        [`&:not(.link_icon_stroke) .${icon} > path`]: {
          color: secondaryColor,
        },
      },

      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        right: 0,
        height: '100%',
        width: '2px',
        backgroundColor: colors.color,
      },
    },
  }
})
