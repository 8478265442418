import React from 'react'
import { Card } from '@/components/Elements'
import { Header } from '../Header'

interface IProps {
  children: React.ReactNode
}

export const GeneralHeader = ({ children }: IProps) => {
  return (
    <Header>
      <Card className={'overflow-visible'}>{children}</Card>
    </Header>
  )
}
