import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const authLogoutSlice = createSlice({
  name: 'authLogout',
  initialState,
  reducers: {
    logout() {
      return initialState
    },
    logoutSuccess() {
      return initialState
    },
  },
})

export const authLogout = authLogoutSlice.actions

export default authLogoutSlice.reducer
