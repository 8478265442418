import axios from 'axios'
import { CreateInputDTO } from './dto'

class InputsAPI {
  getInputs = async (params: any) => {
    return axios.get(`api/inputs`, {
      params,
    })
  }

  createInput = async (data: CreateInputDTO) => {
    return axios.post(`api/inputs`, data)
  }
}

export const inputsAPI = new InputsAPI()
