import { all, fork, put, takeEvery } from 'redux-saga/effects'
import { authData } from '../data/slice'
import { authLogout } from './slice'

function* logout() {
  yield put(authData.cleanData())

  yield put(authLogout.logoutSuccess())
}

function* watchLogout() {
  yield takeEvery(authLogout.logout, logout)
}

export function* logoutSaga() {
  yield all([fork(watchLogout)])
}
