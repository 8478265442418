import * as React from 'react'
import { Box, Drawer } from '@mantine/core'
import { NavbarContent } from '../NavbarContent'
import { Header } from './Header'

interface IProps {
  opened: boolean
  onClose: () => void
}

export const NavbarDrawer = ({ opened, onClose }: IProps) => {
  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      size={300}
      styles={(theme) => ({
        drawer: {
          display: 'flex',
          flexDirection: 'column',
          boxShadow: theme.shadows.xs,
        },
        body: {
          display: 'flex',
          minHeight: '100%',
          flexDirection: 'column',
        },
      })}
    >
      <Header onNavLinkClick={onClose} />

      <Box className={'flex flex-1 overflow-hidden flex-col'}>
        <NavbarContent onNavLinkClick={onClose} />
      </Box>
    </Drawer>
  )
}
