import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useDeepCompareEffect } from 'react-use'
import { Title } from '@mantine/core'
import { Card, MetaPagination, OverlayDataShower } from '@/components/Elements'
import { GeneralPageLayoutShell } from '@/components/Layouts/Shells/GeneralPageLayoutShell'
import { GeneralHeader } from '@/features/users/components/Users/Layouts'
import { GeneralUsersTable } from '@/features/users/components/Users/Tables'
import { useGeneralUsersQueryParam } from '@/features/users/hooks'
import { usersUsersList } from '@/features/users/store'
import { UserModel } from '@/features/users/types'
import { RootState } from '@/store'

interface IProps {
  list: UserModel[]
  loading: boolean
  error: any
  meta: any
}

const GeneralUsersContainerComponent = ({ ...props }: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { page, onPageChange, filters, onFiltersChange } = useGeneralUsersQueryParam()

  const [syncCount, forceUpdate] = React.useReducer((x: number) => x + 1, 0)

  useEffect(() => {
    return () => {
      dispatch(usersUsersList.cleanState())
    }
  }, [])

  useDeepCompareEffect(() => {
    fetchList()
  }, [page, filters, syncCount])

  const syncData = () => {
    forceUpdate()
  }

  const fetchList = () => {
    const params = {
      page: page,
      sort: [{ id: 'created_at', desc: true }],
      filters: {
        ...filters,
      },
    }

    dispatch(usersUsersList.getList({ params }))
  }

  const onFilterFormChange = (values: any) => {
    onFiltersChange(values)
  }

  return (
    <GeneralPageLayoutShell
      header={<GeneralHeader filters={filters} onFiltersChange={onFilterFormChange} />}
    >
      <Title order={3} mb={'md'}>
        {t('users')}
      </Title>

      <OverlayDataShower isLoading={props.loading} isFailed={!!props.error} error={props.error}>
        <Card px={0} py={0}>
          <GeneralUsersTable
            keys={['avatar', 'user', 'email', 'date-registered', 'action']}
            items={props.list}
            loading={props.loading}
            removeControlProps={{ onSuccess: syncData }}
          />
        </Card>

        <MetaPagination meta={props.meta} page={page} onChange={onPageChange} className={'mt-4'} />
      </OverlayDataShower>
    </GeneralPageLayoutShell>
  )
}

const mapStateToProps = (state: RootState) => {
  const { list, loading, error, meta } = state.users.users.list
  return {
    list,
    loading,
    error,
    meta,
  }
}

export const GeneralUsersContainer = connect(mapStateToProps)(GeneralUsersContainerComponent)
