import axios from 'axios'

class UsersAPI {
  getUsers = async (params: any) => {
    return axios.get(`api/admin/users`, {
      params,
    })
  }
}

export const usersAPI = new UsersAPI()
