import { createSlice } from '@reduxjs/toolkit'
import { UserModel } from '@/features/users/types'

interface IState {
  list: UserModel[]
  loading: boolean
  error: any | null
  meta: any | null
}

const initialState: IState = {
  list: [],
  loading: false,
  error: null,
  meta: null,
}

const usersUsersListSlice = createSlice({
  name: 'usersUsersList',
  initialState,
  reducers: {
    getList(state, action) {
      state.loading = true
      state.error = null
    },
    getListSuccess(state, action) {
      state.loading = false
      state.list = action.payload.data
      state.meta = action.payload.meta
    },
    getListError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    updateListItem(state, action) {
      state.list = state.list.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          }
        } else {
          return item
        }
      })
    },
    cleanState() {
      return initialState
    },
  },
})

export const usersUsersList = usersUsersListSlice.actions

export default usersUsersListSlice.reducer
