import React, { useState } from 'react'

export const usePopup = () => {
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(null)

  const open = (data?: any) => {
    data && setData(data)
    setVisible(true)
  }

  const close = (params = { reset: true }) => {
    setVisible(false)

    const { reset } = params

    if (reset) {
      resetData()
    }
  }

  const resetData = () => {
    setData(null)
  }

  const toggle = (data?: any, params = { reset: true }) => {
    visible ? close(params) : open(data)
  }

  return { data, visible, open, close, resetData, toggle }
}
