import axios from 'axios'
import { UpdateInputDTO } from './dto'

class InputAPI {
  getInput = async (id: number, params: any = {}) => {
    return axios.get(`api/inputs/${id}`, {
      params,
    })
  }

  updateInput = async (id: number, data: UpdateInputDTO) => {
    return axios.put(`api/inputs/${id}`, data)
  }

  deleteInput = async (id: number) => {
    return axios.delete(`api/inputs/${id}`)
  }
}

export const inputAPI = new InputAPI()
