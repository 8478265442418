import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIconButton } from '@/components/Elements'
import { UserModel } from '@/features/users/types'

interface IProps {
  user: UserModel
  onSuccess?: (data: any) => void
}

export const EditControl = ({ user, onSuccess }: IProps) => {
  const { t } = useTranslation()

  const { id } = user

  const onEditSuccess = (data: any) => {
    onSuccess && onSuccess(data)
  }

  return (
    <>
      <div>
        <ActionIconButton name={'edit'} onClick={() => {}} />
      </div>
    </>
  )
}
