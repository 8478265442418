import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  return {
    root: {},

    body: {},

    main: {},

    growBlock: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
  }
})
