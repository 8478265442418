import React from 'react'
import { useDispatch } from 'react-redux'
import { authLogout } from '@/features/auth/store'

export const useLogout = () => {
  const dispatch = useDispatch()

  const logout = async () => {
    dispatch(authLogout.logout())
  }

  return {
    logout,
  }
}
