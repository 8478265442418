import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AvatarProvider, TableView } from '@/components/Elements'
import { DateUtils } from '@/utils'
import { Action } from './Action'
import { Email } from './Email'
import { User } from './User'

type Key = 'avatar' | 'user' | 'email' | 'date-registered' | 'action'

interface IProps {
  keys: Key[]
  items: any[]
  loading: boolean
  actionProps?: any
  editControlProps?: any
  removeControlProps?: any
}

export const GeneralUsersTable = ({ keys = [], items, loading, actionProps, ...props }: IProps) => {
  const { t } = useTranslation()

  const [columns, setColumns] = useState<any[]>([])

  const columnConfig: any = {
    avatar: {
      accessorKey: 'avatar',
      id: 'avatar',
      header: null,
      isPlaceholder: true,
      cell: (info: any) => <AvatarProvider avatar={info.row.original?.avatar} size={'md'} />,
      __headerProps: {
        style: {
          width: '70px',
        },
      },
    },
    user: {
      accessorKey: 'user',
      id: 'user',
      header: t('name'),
      cell: (info: any) => <User user={info.row.original} />,
    },
    email: {
      accessorKey: 'email',
      id: 'email',
      header: t('email'),
      cell: (info: any) => <Email user={info.row.original} />,
    },
    'date-registered': {
      accessorKey: 'created_at',
      id: 'created_at',
      header: t('date_registered'),
      cell: (info: any) => (info.getValue() ? DateUtils.formatDate(info.getValue()) : '-'),
    },
    action: {
      accessorKey: 'action',
      header: null,
      isPlaceholder: true,
      cell: (info: any) => (
        <div className={'flex justify-end'}>
          <Action
            {...actionProps}
            user={info.row.original}
            editControlProps={props.editControlProps}
            removeControlProps={props.removeControlProps}
          />
        </div>
      ),
    },
  }

  useEffect(() => {
    const config = []

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (columnConfig[key]) {
        config.push(columnConfig[key])
      }
    }

    setColumns(config)
  }, [])

  return (
    <>
      <TableView data={items} columns={columns} loading={loading} />
    </>
  )
}
