import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  const dark = theme.colorScheme === 'dark'

  return {
    main: {
      display: 'flex',
      backgroundColor: dark
        ? theme.colors.dark[7]
        : theme.fn.rgba(theme.colors[theme.primaryColor][0], 0.5),
    },
  }
})
