import React from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmationControl } from '@/components/Controls'
import { ActionIconButton } from '@/components/Elements'
import { useUsersRemove } from '@/features/users/hooks'
import { UserModel } from '@/features/users/types'

interface IProps {
  user: UserModel
  onSuccess?: (data: any) => void
}

export const RemoveControl = ({ user, onSuccess }: IProps) => {
  const { t } = useTranslation()

  const { loading, onSubmit } = useUsersRemove()

  const onRemoveConfirm = async () => {
    await onSubmit(user?.id, onSuccess)
  }

  return (
    <>
      <ConfirmationControl
        onConfirm={onRemoveConfirm}
        render={({ onOpen }) => (
          <ActionIconButton name={'remove'} onClick={onOpen} disabled={loading} />
        )}
      />
    </>
  )
}
