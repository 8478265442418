import React from 'react'
import { MegaTagTitle } from '@/components/Elements'
import { LoginContainer } from '@/features/auth/components/Login'

export const Login = () => {
  return (
    <>
      <MegaTagTitle title={'login'} />

      <LoginContainer />
    </>
  )
}
