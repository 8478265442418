export const EN = {
  login: 'Login',
  sign_up: 'Sign Up',
  'message.error-fallback': 'Error occurred',
  refresh: 'Refresh',
  'email_verification.success': 'Verification passed successfully',
  welcome_back: 'Welcome back',
  dont_have_account: 'Don’t have an account',
  or: 'or',
  'field.error.required': 'This field is required',
  'field.error.invalid': 'This field is invalid',
  'field.error.password.length': 'The password must be at least {{count}} characters',
  'field.error.password.no_match': 'Passwords do not match',
  error: 'Error',
  email: 'Email',
  password: 'Password',
  confirm_password: 'Confirm password',
  forgot_password: 'Forgot password',
  already_have_account: 'Already have an account',
  required: 'Required',
  reset_password: 'Reset password',
  remember_it: 'Remember it',
  reset: 'Reset',
  back_to: 'Back to',
  terms_and_conditions: 'Terms and conditions',
  logout: 'Logout',
  name: 'Name',
  'confirmation-email-sent': 'We’ve sent you an email to confirm your email address',
  send_confirmation_email_to: 'Send a confirmation email to',
  success: 'Success',
  back: 'Back',
  next: 'Next',
  first_name: 'First name',
  last_name: 'Last name',
  no_options: 'No options',
  description: 'Description',
  start_date: 'Start date',
  end_date: 'End date',
  add: 'Add',
  saved_successfully: 'Saved successfully',
  phone: 'Phone',
  upload_photo: 'Upload photo',
  delete_photo: 'Delete photo',
  delete: 'Delete',
  'file.max_size.error': 'The file must not be greater than {{text}}',
  deleted_successfully: 'Deleted successfully',
  profile: 'Profile',
  yes: 'Yes',
  no: 'No',
  empty: 'Empty',
  not_filled: 'Not filled',
  no_data: 'No data',
  edit_profile: 'Edit profile',
  details: 'Details',
  cancel: 'Cancel',
  save: 'Save',
  skill_already_exist: 'Skill already exist',
  no_info: 'No info',
  from: 'From',
  to: 'To',
  'field.error.date.after-or-equal': 'The value must be a date after or equal to {{date}}.',
  today: 'Today',
  'field.error.date.after': 'The value must be a date after {{date}}.',
  status: 'Status',
  pending: 'Pending',
  active: 'Active',
  collapse: 'Collapse',
  expand: 'Expand',
  date: 'Date',
  confirmation: 'Confirmation',
  please_confirm: 'Please confirm',
  confirm: 'Confirm',
  sent_successfully: 'Sent successfully',
  action: 'Action',
  'field.error.time.after': 'The value must be a time after {{date}}.',
  removed_successfully: 'Removed successfully',
  settings: 'Settings',
  password_changed_successfully: 'Password changed successfully',
  change_password: 'Change password',
  current_password: 'Current password',
  new_password: 'New password',
  confirm_new_password: 'Confirm new password',
  you: 'You',
  attachments: 'Attachments',
  no_messages: 'No messages',
  unread_messages: 'Unread messages',
  write_a_message: 'Write a message',
  select_chat_to_start_messaging: 'Select a chat to start messaging',
  load_more: 'Load more',
  new_message: 'New message',
  download: 'Download',
  pay: 'Pay',
  invoice: 'Invoice',
  home: 'Home',
  register_terms_label: 'I have read and accept the',
  register_terms_text: 'terms of service and conditions',
  dashboard: 'Dashboard',
  chats: 'Chats',
  general_info: 'General info',
  deactivation: 'Deactivation',
  delete_account: 'Delete account',
  account_deleted_successfully: 'Account deleted successfully',
  dark: 'Dark',
  light: 'Light',
  'color-switch-to': 'Switch to {{ scheme }}',
  log_in_to_your_account: 'Log In to your account',
  registration: 'Registration',
  'forgot_password.note':
    'Please enter the email associated with your account and we’ll send you a link to reset your password.',
  forecast: 'Forecast',
  daily_tracker: 'Daily tracker',
  input: 'Input',
  inputs: 'Inputs',
  billing: 'Billing',
  integrations: 'Integrations',
  inputs_and_metrics: 'Inputs and Metrics',
  add_custom_item: 'Add custom item',
  title: 'Title',
  type: 'Type',
  created_date: 'Created date',
  email_verification: 'Email verification',
  'email-verification-label': 'Please Verify Your Email',
  select_period: 'Select period',
  add_custom_calculation: 'Add custom calculation',
  add_value: 'Add value',
  'input.type.default': 'Data query',
  'input.type.custom': 'Custom calculation',
  'input.type.constant': 'Custom value',
  'input.function_type.sum': 'Sum',
  'input.function_type.avg': 'Avg',
  'input.action.multiplication': 'Multiplication',
  'input.action.division': 'Division',
  'input.action.addition': 'Addition',
  'input.action.subtraction': 'Subtraction',
  function_type: 'Function type',
  metric: 'Metric',
  metric_type: 'Metric type',
  value: 'Value',
  'field.error.value.between': 'The value must be between {{min}} and {{max}}',
  edit_custom_calculation: 'Edit custom calculation',
  edit_value: 'Edit value',
  edit: 'Edit',
  types: 'Types',
  search: 'Search',
  'min-characters.count': 'Min. {{count}} characters',
  note: 'Note',
  goals: 'Goals',
  actual: 'Actual',
  users: 'Users',
  date_registered: 'Date registered',
}
