import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { SearchFormSection } from '@/features/filter/components/FormSections'

export const GeneralFilterForm = () => {
  const { t } = useTranslation()

  return (
    <Group align={'flex-start'}>
      <SearchFormSection keywordsMinLength={1} />
    </Group>
  )
}
