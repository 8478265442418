import axios from 'axios'

class UserAPI {
  getUser = async (id: number, params: any) => {
    return axios.get(`api/users/${id}`, {
      params,
    })
  }

  deleteUser = async (id: number) => {
    return axios.delete(`api/admin/users/${id}`)
  }
}

export const userAPI = new UserAPI()
