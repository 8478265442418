import React from 'react'
import { Dashboard } from '@/features/dashboard/routes'
import { ProfileRoot } from '@/features/profile/routes'
import { UsersRoot } from '@/features/users/routes'
import { ROLE } from '@/features/users/types'
import { GUARD } from '../../guards'

type PrivateRouteType = {
  index?: boolean
  path?: string
  element: React.ReactNode
  roles?: Array<string>
  guards: Array<GUARD>
}

const generalRoutes: PrivateRouteType[] = [
  {
    path: 'profile/*',
    element: <ProfileRoot />,
    roles: [ROLE.SuperAdmin],
    guards: [],
  },
  {
    path: 'dashboard',
    element: <Dashboard />,
    roles: [ROLE.SuperAdmin],
    guards: [],
  },
  {
    path: 'users/*',
    element: <UsersRoot />,
    roles: [ROLE.SuperAdmin],
    guards: [],
  },
]

export const getGeneralRoutes = () => {
  return generalRoutes
}
