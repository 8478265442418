import React from 'react'
import { useTranslation } from 'react-i18next'
import { AuthCard } from '@/features/auth/components/Elements'
import { useAppDispatch } from '@/store'
import { authLoginPA } from '../../../store'
import { FormValues, LoginForm } from './LoginForm'

export const LoginContainer = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const handleSubmit = async (values: FormValues) => {
    await dispatch(authLoginPA.request(values))
  }

  return (
    <AuthCard title={t('login')}>
      <LoginForm onSubmit={handleSubmit} />
    </AuthCard>
  )
}
