import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { inputsAPI } from '@/features/input/api'
import i18n from '@/i18n'
import { RequestUtils } from '@/utils'
import { inputInputsList } from './slice'

function* getList({ payload: { params } }: PayloadAction<{ params: any }>) {
  try {
    const requestParams: any = RequestUtils.getMapRequestParams(params)

    const response: AxiosResponse = yield call(inputsAPI.getInputs, requestParams)

    const { data } = response

    const {
      meta: { current_page, last_page },
    } = data

    if (current_page && last_page && current_page > last_page) {
      yield put(
        inputInputsList.getList({
          params: {
            ...params,
            page: last_page,
          },
        })
      )
    } else {
      yield put(inputInputsList.getListSuccess(data))
    }
  } catch (error: any) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(inputInputsList.getListError(message))
  }
}

function* watchGetList() {
  yield takeLatest(inputInputsList.getList, getList)
}

export function* listSaga() {
  yield all([fork(watchGetList)])
}
