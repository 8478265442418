import axios from 'axios'
import { LoginDTO } from './dto'

class AuthAPI {
  login = async (data: LoginDTO) => {
    return axios.post('api/admin/login', data)
  }
}

export const authAPI = new AuthAPI()
