import { combineReducers } from 'redux'
import list from './list/slice'
import query from './query/reducers'

const inputsReducer = combineReducers({
  list,
  query,
})

export default inputsReducer
