import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { inputAPI } from '@/features/input/api'
import i18n from '@/i18n'
import { inputEditData } from './slice'

function* getData({ payload: { id } }: PayloadAction<{ id: number }>) {
  try {
    const params = {
      includes: ['inputTypeConstant', 'inputTypeCustom', 'trackerInput'],
    }

    const response: AxiosResponse = yield call(inputAPI.getInput, id, params)

    const { data } = response

    yield put(inputEditData.getDataSuccess(data))
  } catch (error: any) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(inputEditData.getDataError(message))
  }
}

function* watchGetData() {
  yield takeEvery(inputEditData.getData, getData)
}

export function* dataSaga() {
  yield all([fork(watchGetData)])
}
