import { MantineThemeColorsOverride } from '@mantine/core'

const colors = {
  orange: [
    '#FFF6F0',
    '#FFEDE0',
    '#FFD8BD',
    '#FFC194',
    '#FFA05C',
    '#FF7612',
    '#EB6200',
    '#D65900',
    '#AD4800',
    '#803500',
  ],
}

export const overrideColors: MantineThemeColorsOverride = {
  violet: [
    '#F5F2FD',
    '#ECE5FB',
    '#D6C6F6',
    '#BCA2F0',
    '#966EE8',
    '#431899',
    '#381480',
    '#2E116A',
    '#2E116A',
    '#1F0B47',
  ],
  orange: colors.orange,
  secondary: colors.orange,
}
