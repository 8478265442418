import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { userAPI } from '@/features/users/api'
import { FormTypes } from '@/types'
import { USERS_REMOVE_FAILED, USERS_REMOVE_REQUEST, USERS_REMOVE_SUCCESS } from './actionTypes'

type ArgsType = {
  id: number
}

export const usersRemovePA = createPromiseAction(
  USERS_REMOVE_REQUEST,
  USERS_REMOVE_SUCCESS,
  USERS_REMOVE_FAILED
)<ArgsType, unknown, AxiosError<FormTypes.ValidationErrors>>()

function* remove(action: PromiseAction<string, ArgsType, any>) {
  try {
    const { id } = action.payload

    const response: AxiosResponse = yield call(userAPI.deleteUser, id)
    const { data } = response

    yield put(usersRemovePA.success({}))

    resolvePromiseAction(action, {})
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* removeSaga() {
  yield takeEvery(usersRemovePA.request, remove)
}
