import * as React from 'react'
import { Link } from 'react-router-dom'
import { Burger, Group, Header as MantineHeader, MediaQuery, useMantineTheme } from '@mantine/core'
import { Anchor, LogoIcon } from '@/components/Elements'
import { HEADER_HEIGHT } from '@/const/layout'
import useStyles from './Header.styles'
import { ProfileMenu } from './ProfileMenu'

interface IProps {
  onDrawerOpen: () => void
}

export const Header = ({ onDrawerOpen }: IProps) => {
  const { classes, cx } = useStyles()
  const theme = useMantineTheme()

  return (
    <MantineHeader
      classNames={classes}
      height={HEADER_HEIGHT}
      withBorder={theme.colorScheme === 'dark'}
      zIndex={101}
    >
      <Group className={'w-full flex-nowrap'}>
        <div>
          <Group className={'flex-nowrap'}>
            <MediaQuery largerThan="md" styles={{ display: 'none', height: 0 }}>
              <Burger opened={false} onClick={onDrawerOpen} size="sm" />
            </MediaQuery>

            <Anchor component={Link} to={'/'}>
              <LogoIcon size={'sm'} />
            </Anchor>
          </Group>
        </div>

        <div className={'flex grow justify-end'}>
          <ProfileMenu />
        </div>
      </Group>
    </MantineHeader>
  )
}
