import { debounce } from 'lodash'
import React, { useCallback, useEffect } from 'react'

export const useFormValuesChangedDebounce = (
  watch: any,
  callback: (data: any) => void,
  delay = 700,
  debounceChangeCondition?: (values: any, meta: { name?: string; type?: string }) => boolean
) => {
  const onDebouncedChange = useCallback(debounce(callback, delay), [callback])

  useEffect(() => {
    const subscription = watch((values: any, { name, type }: { name?: string; type?: string }) => {
      if (type) {
        // type === 'change'

        const debounce = debounceChangeCondition
          ? debounceChangeCondition(values, { name, type })
          : true

        if (debounce) {
          onDebouncedChange(values)
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    return () => {
      onDebouncedChange.cancel()
    }
  }, [])
}
