import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { PageContent } from '@/components/Layouts'
import { GeneralUsersContainer } from '@/features/users/components/Users/GeneralUsers'

export const Users = () => {
  const { t } = useTranslation()
  return (
    <>
      <MegaTagTitle title={'users'} />

      <PageContent>
        <GeneralUsersContainer />
      </PageContent>
    </>
  )
}
