import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Alert, Button } from '@/components/Elements'
import { PasswordInputController, TextInputController } from '@/components/FormElements'
import { PASSWORD_MIN_LENGTH } from '@/const/form'
import { useFormSubmit } from '@/hooks'

export type FormValues = {
  email: string
  password: string
}

interface IProps {
  onSubmit: (values: FormValues) => Promise<void>
}

export const LoginForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().email('field.error.invalid').required('field.error.required'),
        password: Yup.string()
          .required('field.error.required')
          .min(PASSWORD_MIN_LENGTH, 'field.error.password.length'),
      })
    ),
  })

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await onFormSubmit(data)
    } catch (error) {}
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.message || t('error')}
          </Alert>
        )}

        <TextInputController
          control={control}
          name={'email'}
          id={'email'}
          label={t('email')}
          placeholder={t('email')}
          mb={'md'}
        />

        <PasswordInputController
          control={control}
          name={'password'}
          id={'password'}
          label={t('password')}
          placeholder={t('password')}
          mb={'md'}
          translateParams={{ count: PASSWORD_MIN_LENGTH }}
        />

        <Button mt={'lg'} fullWidth type={'submit'} loading={isSubmitting}>
          {t('login')}
        </Button>
      </form>
    </>
  )
}
