import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Anchor, Text } from '@mantine/core'
import { useUsersModel } from '@/features/users/hooks'
import { UserModel } from '@/features/users/types'

interface IProps {
  user: UserModel
}

export const User = ({ user }: IProps) => {
  const { t } = useTranslation()

  const { id } = user

  const { getName } = useUsersModel()

  return (
    <div>
      <div>
        <Text>{getName(user) || '-'}</Text>
        {/*<Anchor component={Link} to={``} color={'dark'}>*/}
        {/*  {getName(user) || '-'}*/}
        {/*</Anchor>*/}
      </div>
    </div>
  )
}
