import React from 'react'
import logoDark from '@/assets/images/logo/logo-dark.png'
import logoLight from '@/assets/images/logo/logo-light.png'

type SizeType = 'sm'

type AttrsType = {
  height?: string
}

interface IProps {
  dark?: boolean
  size?: SizeType | undefined
}

export const Logo = ({ dark, size, ...props }: IProps) => {
  let attrs: AttrsType = {}

  if (size === 'sm') {
    attrs = {
      ...attrs,
      height: '32px',
    }
  }

  return <img src={dark ? logoLight : logoDark} alt={'logo'} {...attrs} {...props} />
}
