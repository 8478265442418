import { isEqual } from 'lodash'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useFormValuesChangedDebounce } from '@/hooks'

interface IProps {
  config?: object
  onFiltersChange: (data: any) => void
  initialValues?: object
  delay?: number
  debounceChangeCondition?: (values: any, meta: { name?: string; type?: string }) => boolean
}

export const useFilterForm = ({
  config = {},
  onFiltersChange,
  initialValues = {},
  delay = 700,
  debounceChangeCondition,
}: IProps) => {
  const methods = useForm({
    ...config,
  })

  const { reset, watch } = methods

  useFormValuesChangedDebounce(watch, onFiltersChange, delay, debounceChangeCondition)

  const watchValues = watch()

  const isFiltersApplied = useMemo(() => {
    return !isEqual(watchValues, initialValues)
  }, [watchValues])

  const onReset = () => {
    reset(initialValues)
  }

  return {
    methods,
    isApplied: isFiltersApplied,
    onReset,
  }
}
