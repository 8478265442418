import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUsersModel } from '@/features/users/hooks'
import { UserModel } from '@/features/users/types'
import { EditControl, RemoveControl } from './Controls'

interface IProps {
  config?: {
    edit: boolean
    remove: boolean
  }
  user: UserModel
  editControlProps?: any
  removeControlProps?: any
}

const defaultConfig = {
  edit: false,
  remove: true,
}

export const Action = ({ user, config, editControlProps, removeControlProps }: IProps) => {
  const { t } = useTranslation()

  config = {
    ...defaultConfig,
    ...config,
  }

  const usersModel = useUsersModel()

  return (
    <div className={'flex gap-4 items-center'}>
      {config?.edit && <EditControl user={user} {...editControlProps} />}

      {config?.remove && !usersModel.isAdmin(user) && (
        <RemoveControl user={user} {...removeControlProps} />
      )}
    </div>
  )
}
