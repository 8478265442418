import React from 'react'
import useStyles from './GeneralPageLayoutShell.styles'

interface IProps {
  children?: React.ReactNode
  header?: React.ReactNode
  grow?: boolean
}

export const GeneralPageLayoutShell = ({ children, header, grow = false }: IProps) => {
  const { classes, cx } = useStyles()

  return (
    <div
      className={cx(classes.root, {
        [`${classes.growBlock}`]: grow,
      })}
    >
      {header}
      <div
        className={cx(classes.body, {
          [`${classes.growBlock}`]: grow,
        })}
      >
        <div
          className={cx(classes.main, {
            [`${classes.growBlock}`]: grow,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
