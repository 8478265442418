import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { GeneralHeader as GeneralPageHeader } from '@/components/Layouts/Shells/GeneralPageLayoutShell'
import { GeneralUsersFilter } from '@/features/users/components/Users/Filters'

interface IProps {
  filters: {}
  onFiltersChange: (data: object) => void
}

export const GeneralHeader = ({ filters, onFiltersChange }: IProps) => {
  const { t } = useTranslation()

  return (
    <GeneralPageHeader>
      <Group align={'flex-start'}>
        <div className={'flex flex-wrap grow justify-end'}>
          <GeneralUsersFilter values={filters} onFiltersChange={onFiltersChange} />
        </div>
      </Group>
    </GeneralPageHeader>
  )
}
