import React from 'react'
import { Navigate } from 'react-router-dom'
import { AuthLayout } from '@/components/Layouts'
import { Login } from '@/features/auth/routes'
import { AuthGuard } from '@/routes/components'

//config
export const getAuthRoutes = (isLoggedIn: boolean) => {
  return [
    {
      path: '/*',
      element: <AuthGuard isLoggedIn={isLoggedIn} />,
      children: [
        {
          index: !isLoggedIn,
          element: <Navigate to={'/login'} />,
        },
        {
          element: <AuthLayout />,
          children: [
            {
              path: `login`,
              element: <Login />,
            },
          ],
        },
      ],
    },
  ]
}
