import axios from 'axios'

class ProfileAPI {
  getProfile = async () => {
    return axios.get('api/profile')
  }

  updatePassword = async (data: any) => {
    return axios.put('api/profile/password', data)
  }
}

export const profileAPI = new ProfileAPI()
