import { combineReducers } from 'redux'
import edit from './edit/reducers'
import inputs from './inputs/reducers'

const inputReducer = combineReducers({
  inputs,
  edit,
})

export default inputReducer
