import React from 'react'
import { BsChevronDown, BsGear, BsPlus, BsSearch } from 'react-icons/bs'

export type IconNameType = 'plus' | 'search' | 'gear' | 'chevron-down'

interface IProps {
  name: IconNameType
  size?: number
  color?: string
  className?: string

  [x: string]: any
}

export const Icon = ({ name, ...props }: IProps) => {
  switch (name) {
    case 'plus':
      return <BsPlus {...props} />

    case 'search':
      return <BsSearch {...props} />

    case 'gear':
      return <BsGear {...props} />

    case 'chevron-down':
      return <BsChevronDown {...props} />

    default:
      return null
  }
}
