import React from 'react'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'

export const useGeneralUsersQueryParam = () => {
  const [query, setQuery] = useQueryParams(
    {
      page: NumberParam,
      keywords: withDefault(StringParam, ''),
    },
    { removeDefaultsFromUrl: true }
  )

  const { page, ...restQuery } = query

  const filters = {
    ...restQuery,
  }

  const onPageChange = (page?: number) => {
    setQuery({ page })
  }

  const onFiltersChange = (data?: object) => {
    const params = {
      ...data,
    }

    setQuery({ page: undefined, ...params })
  }

  return {
    query,
    setQuery,
    page,
    onPageChange,
    filters,
    onFiltersChange,
  }
}
