import React from 'react'
import { useTranslation } from 'react-i18next'
import { usersRemovePA } from '@/features/users/store'
import { useAsyncDispatch, useNotify } from '@/hooks'

export const useUsersRemove = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const { loading, onAsyncDispatch } = useAsyncDispatch()

  const onSubmit = async (id: number, onSuccess?: (data: object) => void) => {
    try {
      const data = await onAsyncDispatch(usersRemovePA.request, { id })

      onSuccess && onSuccess({ ...data })

      showNotification({
        type: 'success',
        message: t('removed_successfully'),
      })
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return {
    onSubmit,
    loading,
  }
}
