import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Users } from '../users'

export const UsersRoot = () => {
  return (
    <Routes>
      <Route index element={<Users />} />

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
