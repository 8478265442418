import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Anchor, Text } from '@mantine/core'
import { UserModel } from '@/features/users/types'

interface IProps {
  user: UserModel
}

export const Email = ({ user }: IProps) => {
  const { t } = useTranslation()

  const { id, email } = user

  return <Text>{email || '-'}</Text>

  // return (
  //   <Anchor component={Link} to={``} color={'dark'}>
  //     {email || '-'}
  //   </Anchor>
  // )
}
